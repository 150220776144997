

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SidemenuEntry extends Vue {
  @Prop() title!: string | undefined;

  @Prop() link!: string | undefined;

  @Prop() icon!: string | undefined;
}
