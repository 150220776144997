


























import {Component, Emit, Prop, VModel, Vue, Watch} from 'vue-property-decorator'

@Component({
  components: {}
})
export default class TimePickerDialog extends Vue {
  @VModel() originalTimeValue!: string;

  @Prop() title!: string;

  time: string = '';

  created() {
    this.time = this.originalTimeValue;
  }

  allowedMinutes(v) {
    return v % 15 === 0;
  }

  @Emit('change')
  save() {
    this.openDialog = false;
    return this.time;
  }

  openDialog: boolean = false;
}
