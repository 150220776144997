























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";
import {RequestType} from "@/assets/constants/Enums";
import GenericEntity, {Wrapper} from "@/assets/Entities/GenericEntity";
import {DataPagination} from "vuetify";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {}
})
export default class AddElementDialog<T extends GenericEntity> extends Vue {
  openDialog: boolean = false;
  searchInternal: string = '';
  elements: T[] = [];
  loadingList: string[] = [];
  options = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false
  }
  pagination: DataPagination = {
    page: 1,
    itemsPerPage: 5,
    pageStart: 1,
    pageStop: 1,
    pageCount: 1,
    itemsLength: 0
  }

  @Prop() urlParams!: SchoolURLParams;
  @Prop() rowsPerPageText!: string;
  @Prop() schoolClassId!: string;
  @Prop() classList!: string[];
  @Prop() reloadList!: () => void;
  @Prop() addCallback!: (element: T, callbackSuccess: () => void, callbackFinally: () => void) => void;
  @Prop() getSubtitleForElement!: (element: T) => string;

  get search(): string {
    return this.searchInternal;
  }

  set search(search: string) {
    clearTimeout(this.$data._timerId)

    // delay new call 500ms
    this.$data._timerId = setTimeout(() => {
      this.searchInternal = search
    }, 500)
  }

  @Watch('openDialog')
  @Watch('searchInternal')
  loadUser(): void {
    if (this.openDialog) {
      let options: any = this.options;
      options.search = this.searchInternal;
      Webservice.performRequest<Wrapper<T>>(RequestType.GET, this.urlParams, options,
          val => {
            this.pagination.itemsLength = (val as Wrapper<T>).count;
            this.elements = (val as Wrapper<T>).items as T[];
          })
    }
  }

  addToClass(element: T) {
    this.loadingList.push(element._id);
    this.addCallback(element,
        this.reloadList,
        () => {
          this.loadingList = this.loadingList.filter(value => value !== element._id)
        });
  }
}
