import {TargetType} from "@/assets/constants/Enums";

export default class Endpoints {
    public static LOGIN_ENDPOINT: string = 'login';

    private static API_PREFIX: string = 'api/v1/';

    /****************************************************************
     ******************* School related Endpoints *******************
     ****************************************************************/
    private static SCHOOL_GENERAL_ENDPOINT: string = Endpoints.API_PREFIX + 'schools';
    private static SCHOOL_SPECIFIC_ENDPOINT: string = '/school/';

    /***************************************************************
     ******************* Class related Endpoints *******************
     ***************************************************************/
    private static CLASS_GENERAL_ENDPOINT: string = '/classes';
    private static CLASS_SPECIFIC_ENDPOINT: string = '/class/';

    /****************************************************************
     ******************** User related Endpoints ********************
     ****************************************************************/

    private static USER_GENERAL_ENDPOINT: string = '/users';
    private static USER_SPECIFIC_ENDPOINT: string = '/user/';
    private static USER_QUESTIONS_ENDPOINT: string = '/questions';

    public static getSchoolURL(params: SchoolURLParams): string {
        let url = this.SCHOOL_GENERAL_ENDPOINT;

        if (params.schoolId) {
            url += this.SCHOOL_SPECIFIC_ENDPOINT + params.schoolId;

            if (params.type === TargetType.CLASS || (params.classId && params.classId !== '')) {
                url += this.CLASS_GENERAL_ENDPOINT;
                if (params.classId) {
                    url += this.CLASS_SPECIFIC_ENDPOINT + params.classId;
                    if (params.type === TargetType.USER) {
                        url += this.USER_GENERAL_ENDPOINT;
                    }
                }
            } else if (params.type === TargetType.USER || (params.userId && params.userId !== '')) {
                url += this.USER_GENERAL_ENDPOINT;
                if (params.userId) {
                    url += this.USER_SPECIFIC_ENDPOINT + params.userId;
                }
            }
            if (params.type === TargetType.QUESTION) {
                url += this.USER_QUESTIONS_ENDPOINT;
            }
        }
        return url;
    }
}

export class SchoolURLParams {
    type!: TargetType;
    schoolId?: string;
    classId?: string;
    userId?: string;
}
