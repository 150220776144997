import axios from 'axios';
import store from '@/store';

const baseDomain: string = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000/';

const axiosInstance = axios.create({
    baseURL: baseDomain,
    withCredentials: true
});

axiosInstance.interceptors.response.use(value => {return value}, error => {
    if (error?.response?.data?.error === undefined) {
        switch (error.response.status) {
            case 400:
                store.commit('SET_GLOBAL_ERROR', 'BadRequest');
                break;
            case 404:
                store.commit('SET_GLOBAL_ERROR', 'NotFound');
                break;
            case 500:
                store.commit('SET_GLOBAL_ERROR', 'ServerError');
                break;
            default:
        }
    }
    return  Promise.reject(error);
})

export default axiosInstance;

export const {CancelToken} = axios;
