
















import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class ErrorDialog extends Vue {
  get errorType(): string {
    return this.$store.getters.globalError;
  }

  set errorType(type: string) {
    this.$store.commit('SET_GLOBAL_ERROR', type);
  }

  get open(): boolean {
    return this.errorType !== 'NoError' && this.errorType !== '';
  }

  close() {
    this.errorType = 'NoError'
  }
}
