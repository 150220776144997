


























































import {Component, Vue} from 'vue-property-decorator';
import Questionnaire from "@/components/Questionnaire.vue";
import ContactDialog from "@/components/contacts/ContactDialog.vue";
import Symptoms from "@/components/Symptoms.vue";
import UserEditor from "@/components/user/UserEditor.vue";
import {QuestionStatus} from "@/assets/Entities/User";

@Component({
  components: {
    UserEditor,
    Symptoms,
    ContactDialog,
    Questionnaire
  },
})
export default class Home extends Vue {
  questionnaireDialogOpen: boolean = false;
  symptomsDialogOpen: boolean = false;
  contactDialogOpen: boolean = false;

  showSuccessDialog: boolean = false;
  status: QuestionStatus = QuestionStatus.GoodToGo;

  get successMessage(): string {
    switch (this.status) {
      case QuestionStatus.ShouldCheck:
        return 'ShouldCheck';
      case QuestionStatus.Quarantine:
        return 'Quarantine';
      case QuestionStatus.GoodToGo:
        default:
        return 'GoodToGo';
    }
  }

  openSuccessDialog(status: QuestionStatus) {
    this.status = status;
    this.showSuccessDialog = true;
  }
}
