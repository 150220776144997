export enum UserType {
    Site_admin = 'site_admin',
    Admin = 'admin',
    Teacher = 'teacher',
    Student = 'student'
}

export const enum RequestType {
    GET = 'GET',
    POST = 'ADD',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
}

export const enum TargetType {
    SCHOOL,
    CLASS,
    USER,
    QUESTION
}
