















import {Component, Vue} from "vue-property-decorator";
import AnimatedBurger from "@/components/general/layout/AnimatedBurger.vue";

@Component({components: {AnimatedBurger}})
export default class TopBar extends Vue {
}
