



















































import {Component, Prop, Vue} from "vue-property-decorator";
import InputComp from "@/components/general/InputComp.vue";
import {RequestType, TargetType} from "@/assets/constants/Enums";
import Webservice from "@/assets/service/Webservice";

@Component({
  components: {InputComp}
})
export default class Questionnaire extends Vue {
  @Prop() onSend!: () => void;

  loading: boolean = false;

  questions: string[] = [
    "feel",
    "cough",
    "breath",
    "throat",
    "taste_smell",
    "other_person_symptoms",
    "other_person_test",
    "contact"
  ];

  currentStep: number = 0;

  answers: Map<string, (boolean | undefined)> = new Map<string, boolean>();

  nextQuestion(): void {
    if (this.currentStep < (this.questions.length)) {
      this.currentStep++;
    }

    if (!this.answers.has(this.questions[this.currentStep])
        && this.currentStep < (this.questions.length)) {
      this.answers.set(this.questions[this.currentStep], undefined);
    }
  }

  send(): void {
    this.loading = true;
    Webservice.performRequest(RequestType.POST,
        {
          type: TargetType.QUESTION,
          schoolId: this.$store.getters.currentUser.schoolId || this.$store.getters.currentUser.school?._id,
          userId: this.$store.getters.currentUser._id
        },
        this.answers,
        val => {
        },
        error => {
        },
        () => {
          this.answers = new Map<string, boolean>();
          this.loading = false;
          this.onSend();
        });
  }
}
