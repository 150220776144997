import Repository from "@/assets/service/Repository";
import router from "@/router";
import {setId, unsetId} from "@/assets/util/AuthUtil";
import Endpoints, {SchoolURLParams} from "@/assets/service/Endpoints";
import {AxiosResponse} from "axios";
import {RequestType} from "@/assets/constants/Enums";
import {Error} from "@/assets/Entities/Error";
import store from "@/store";
import User from "@/assets/Entities/User";

export default class Webservice {
    public static login(user: string, password: string, callbackFail: (reason) => void, callbackFinally: () => void) {
        Repository.post<User>(Endpoints.LOGIN_ENDPOINT, {login: user, password: password})
            .then(value => {
                Repository.defaults.headers.common = {'_id': value.data._id};
                setId(value.data._id);
                // const user1 = new User(value.data.name, value.data.role,);
                store.commit('SET_CURRENT_USER', value.data);
                if (router.currentRoute.query.redirect) {
                    router.replace(router.currentRoute.query.redirect as string);
                } else {
                    router.replace('/');
                }
            }).catch(callbackFail)
            .finally(callbackFinally);
    }

    public static logout() {
        // Repository.post(this.LOGOUT_ENDPOINT)
        //     .then(value => {
        Repository.defaults.headers.common = {'_id': ''};
        unsetId();
        location.reload();
        //     })
    }

    public static performRequest<T>(type: RequestType,
                                    params: SchoolURLParams,
                                    item: T | any,
                                    callbackSuccess?: (val?: T | string) => void,
                                    callbackFail?: (error: any) => void,
                                    callbackFinally?: () => void,
                                    header?: Object, urlAddition?: string,
                                    progressSetter?: (progress) => void): void {

        let url: string = Endpoints.getSchoolURL(params);

        if (urlAddition) {
            url += urlAddition;
        }

        let request: Promise<AxiosResponse<T | Error>>;

        switch (type) {
            case RequestType.GET:
                request = Repository.get<T | Error>(url, {params: item});
                break;
            case RequestType.DELETE:
                request = Repository.delete<T | Error>(url, {params: item});
                break;
            case RequestType.POST:
                request = Repository.request<T | Error>({
                    url: url,
                    method: 'POST',
                    data: item,
                    headers: header,
                    onUploadProgress: progressSetter
                })
                // url, item, {
                //     headers: header,
                //     onUploadProgress: progressSetter
                // });
                break;
            case RequestType.UPDATE:
                request = Repository.patch<T | Error>(url, item, {headers: header});
                break;
            default:
                throw new TypeError('Unsupported type: ' + type);
        }

        request.then(value => {
            if (value.data instanceof Error && value.data.error && callbackFail) {
                callbackFail(value.data.error);
            } else if (value.data && callbackSuccess) {
                callbackSuccess(value.data as T);
            }
        }).catch(callbackFail)
            .finally(callbackFinally);
    }
}
