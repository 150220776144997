import {UserType} from "@/assets/constants/Enums";
import GenericEntity from "@/assets/Entities/GenericEntity";
import School from "@/assets/Entities/School";
import SchoolClass from "@/assets/Entities/SchoolClass";

export default class User extends GenericEntity{
    name?: string;
    login?: string;
    _id!: string;
    needsTest?: boolean;
    timecreated?: string;
    active?: boolean;
    mail?: string;
    last_questions?: string;
    schoolId!: string;
    role?: UserType;
    password?: string;
    school?: School;
    questions?: Question[];
    classId?: string;
    class?: SchoolClass;
    classes?: SchoolClass[];
    notification_mail?: boolean;
    notification_time?: string;
    headTeacher: boolean = false;

    lastQuestionsLocalized: string = '';

    constructor(name?: string, role?: UserType, schoolId?: string) {
        super();
        this.name = name ?? '';
        this.role = role;
        this.mail = '';
        this.login = '';
        this.password = '';
        this.active = true;
        this.schoolId = schoolId ?? '';
        this.notification_mail = false;
        this.notification_time = '';
    }
}

export class Question{
    date?: string;
    status?: QuestionStatus;
    symptoms!: string[];
    img?: string;
    ext?: string;
}

export const enum QuestionStatus {
    GoodToGo,
    ShouldCheck,
    Quarantine
}
