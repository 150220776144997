





















import {Component, Prop, VModel, Vue} from 'vue-property-decorator'

@Component({
  components: {}
})
export default class DatePicker extends Vue {
  @VModel({type: String}) date!: string;
  @Prop() onlyFuture!: Boolean;
  open: boolean = false;

  get dateString(): string {
    if (this.date.length > 0) {
      return this.$d(new Date(this.date), 'long').toString();
    }
    return ''
  }
}
