import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue';
import Login from "@/views/Login.vue";
import {UserType} from "@/assets/constants/Enums";
import Report from "@/views/Report.vue";
import {isLoggedIn} from "@/assets/util/AuthUtil";
import store from "@/store";


Vue.use(VueRouter)

export const mainSites: Array<RouteConfig> = [
  {
    path: '/schoolList',
    name: 'SchoolList',
    component: () => import('@/views/lists/SchoolList.vue'),
    meta: {
      needsLogin: true,
      neededUser: UserType.Admin,
      icon: 'domain'
    }
  },
  {
    path: '/classList',
    name: 'ClassList',
    component: () => import('@/views/lists/ClassList.vue'),
    meta: {
      needsLogin: true,
      neededUser: UserType.Teacher,
      icon: 'account-group'
    }
  },
  {
    path: '/userList',
    name: 'UserList',
    component: () => import('@/views/lists/UserList.vue'),
    meta: {
      needsLogin: true,
      neededUser: UserType.Teacher,
      icon: 'account'
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('@/views/Calendar.vue'),
    meta: {
      needsLogin: true,
      neededUser: UserType.Teacher,
      icon: 'calendar-month'
    }
  },
  // {
  //   path:'/settings',
  //   name: 'Settings',
  //   component: Settings,
  //   meta: {
  //     needsLogin: true,
  //     neededUser: UserType.Student,
  //     icon: 'wrench'
  //   }
  // },
  {
    path: '/',
    name: 'Questionnaire',
    component: Home,
    meta: {
      needsLogin: true,
      neededUser: UserType.Student,
      icon: 'file-question-outline',
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      needsLogin: true,
      neededUser: UserType.Student,
      icon: 'bullhorn'
    }
  }
];

const routes: Array<RouteConfig> = mainSites.concat([
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

]);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!isLoggedIn()) {
    if (!to.matched[0].meta.needsLogin) {
      next();
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    if (to.path === '/login' || !checkRole(to.matched[0].meta.neededUser)) {
      next('/');
    } else {
      next();
    }
  }
})

export default router

export function checkRole(role: UserType): boolean {
  const userRole = store.getters.currentUser.role;
  return (role === UserType.Student)
      || ((userRole === UserType.Site_admin || userRole === UserType.Teacher)
          && ((role === UserType.Teacher)
              || (userRole === UserType.Site_admin && role === UserType.Site_admin)))
      || (userRole === UserType.Admin && role === UserType.Admin);

}
