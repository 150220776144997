import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import DateTimeFormats = VueI18n.DateTimeFormats;

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  // @ts-ignore
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats = {
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
};

const dateTimeFormats: DateTimeFormats = {
  de: {
    long: {
      day: 'numeric', month: 'long', year: 'numeric',
    },
    day: {
      weekday: 'long',
    },
    short: {
      day: 'numeric', month: 'numeric',
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    dateTime: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    }
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages(),
  numberFormats,
  dateTimeFormats,
});
